import React, { useEffect, useState } from "react";
import { auth, db } from "./firebase"; // Adjust this path as needed
import { collection, query, where, getDocs } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import Header from "./components/Header";
import { useAuth } from "./AuthProvider";

function ReferralsList() {
    const { userData } = useAuth();
    const [referrals, setReferrals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchReferrals = async (userId) => {
            setLoading(true);
            try {
                const referralsRef = collection(db, "referrals");
                const q = query(referralsRef, where("referrerId", "==", userId));
                const querySnapshot = await getDocs(q);

                const referralsData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setReferrals(referralsData);
            } catch (err) {
                console.error("Error fetching referrals: ", err);
                setError("Failed to load referrals. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchReferrals(user.uid);
            } else {
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <>
            <Header />
            <div className="flex items-center justify-center p-6">
                <div className="container mx-auto bg-white shadow-lg rounded-lg p-8">

                    <div className="flex justify-between items-center mb-6">
                        <h2 className="text-3xl font-boldtext-gray-800">My Referrals</h2>
                        <p className="text-green-500">UND Token Rewards: {userData?.referralCoins || 0}</p>
                    </div>

                    {loading ? (
                        <div className="flex justify-center">
                            <svg className="animate-spin h-8 w-8 text-cyan-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                            </svg>
                        </div>
                    ) : error ? (
                        <div className="text-red-500 text-center">{error}</div>
                    ) : (
                        <div className="overflow-x-auto">
                            {referrals.length > 0 ? (
                                <table className="min-w-full bg-white">
                                    <thead>
                                        <tr>
                                            <th className="py-2 px-4 bg-gray-100 text-left text-sm font-medium text-gray-600">Referred User ID</th>
                                            <th className="py-2 px-4 bg-gray-100 text-left text-sm font-medium text-gray-600">Referral Date</th>
                                            {/* Additional columns can be added here if needed */}
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {referrals.map((referral) => (
                                            <tr key={referral.id}>
                                                <td className="py-2 px-4 text-sm text-gray-900">{referral.referredUserId}</td>
                                                <td className="py-2 px-4 text-sm text-gray-500">{new Date(referral.timestamp?.seconds * 1000).toLocaleDateString()}</td>
                                                {/* Additional columns data */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p className="text-center text-gray-500">You have not made any referrals yet.</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default ReferralsList;
