import { signOut } from "firebase/auth";
import React, { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { auth } from "../firebase";

const AdminLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Mobile Sidebar Toggle Button */}
      <button
        onClick={toggleSidebar}
        className="md:hidden p-4 text-white bg-gray-800 fixed z-20 ml-6 mt-2 rounded-lg"
      >
        {isSidebarOpen ? "Close" : "Menu"}
      </button>

      {/* Sidebar */}
      <nav
        className={`fixed md:relative z-10 bg-gray-800 text-white p-6 w-64 h-full overflow-y-auto transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0 transition-transform duration-200 ease-in-out`}
      >
        <ul className="space-y-4 mt-14 lg:mt-0">
          <li>
            <Link
              to="/admin/dashboard"
              className="block py-2 px-4 rounded hover:bg-gray-700"
              onClick={() => setIsSidebarOpen(false)}
            >
              Dashboard
            </Link>
          </li>
          <li>
            <Link
              to="/admin/listing"
              className="block py-2 px-4 rounded hover:bg-gray-700"
              onClick={() => setIsSidebarOpen(false)}
            >
              Listing
            </Link>
          </li>
          <li>
            <Link
              to="/admin/chats"
              className="block py-2 px-4 rounded hover:bg-gray-700"
              onClick={() => setIsSidebarOpen(false)}
            >
              Inbox
            </Link>
          </li>
          <li>
            <button
              onClick={() => {
                handleLogout();
                setIsSidebarOpen(false);
              }}
              className="block py-2 px-4 text-red-600 rounded hover:bg-gray-700 w-full text-left"
            >
              Logout
            </button>
          </li>
        </ul>
      </nav>

      {/* Main Content Area */}
      <div
        className={`flex-1 p-6  bg-gray-100 overflow-y-auto transition-transform duration-200 ease-in-out ${
          isSidebarOpen ? "translate-x-64" : ""
        } md:translate-x-0`}
      >
        <div className="mt-14 lg:mt-0">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
