import React, { useState, useEffect, useMemo, useCallback } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { geoCentroid, geoAlbersUsa } from "d3-geo";
import { auth, db } from "./firebase";
import {
  collection,
  getDocs,
  query,
  limit,
  startAfter,
  orderBy,
  where,
  addDoc,
  doc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import Header from "./components/Header";
import categories from "./data/categories.json";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "./components/Spinner";
import { useAuth } from "./AuthProvider";
import Footer from "./components/Footer";

function Home() {
  const { userData } = useAuth();
  const [user] = useAuthState(auth);
  const [submitting, setSubmitting] = useState(false);
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [contractors, setContractors] = useState([]);
  const [stats, setStats] = useState({});
  const [filteredContractors, setFilteredContractors] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [lastDoc, setLastDoc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedContractorListing, setSelectedContractorListing] =
    useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Extract the 'referer' query parameter
    const params = new URLSearchParams(location.search);
    const referer = params.get('referer');

    if (referer) {
      // Store 'referer' in local storage or state management
      localStorage.setItem('referer', referer);
    }
  }, [location.search]);

  const [formData, setFormData] = useState({
    requirements: [], // Array to store question and answer pairs
  });

  const [questions, setQuestions] = useState([]);

  const fetchQuestions = useCallback(async (category) => {
    try {
      const docRef = doc(db, "questions", category);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setQuestions(docSnap.data().questions || []);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  }, []);

  useEffect(() => {
    if (selectedContractorListing?.category) {
      fetchQuestions(selectedContractorListing.category);
    }
  }, [selectedContractorListing, fetchQuestions]);

  const renderCategorySpecificQuestions = useMemo(() => {
    if (!questions) return null;

    return questions.map((question, index) => (
      <div key={index} className="mt-4">
        <p className="mt-2">{question}</p>
        <textarea
          name={`question_${index}`}
          value={formData.requirements[index]?.answer || ""}
          onChange={(e) => handleFormChange(e, index, question)}
          className="mt-4 p-2 border w-full focus:outline-none"
          rows={2}
          required
        ></textarea>
      </div>
    ));
  }, [questions, formData.requirements]);

  const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";
  const projection = geoAlbersUsa();

  useEffect(() => {
    fetchContractors();
    fetchStats();
  }, []);

  useEffect(() => {
    filterContractors();
  }, [selectedState, selectedCategory, zipCode, contractors]);

  const fetchContractors = async (loadMore = false) => {
    setLoading(true);

    let contractorsQuery = query(
      collection(db, "listings"),
      where("status", "==", "approved"),
      limit(10)
    );

    if (loadMore && lastDoc) {
      contractorsQuery = query(
        collection(db, "listings"),
        startAfter(lastDoc),
        where("status", "==", "approved"),
        limit(10)
      );
    }

    const querySnapshot = await getDocs(contractorsQuery);

    // Fetch associated user data for each listing
    const contractorsList = await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const listingData = doc.data();

        // Assuming the listing contains a `userRef` field that is a reference to the user document
        const userRef = listingData.userRef;

        // Fetch the user data
        const userDoc = await getDoc(userRef);
        const userData = userDoc.exists() ? userDoc.data() : {};

        return {
          id: doc.id,
          ...listingData,
          user: {
            uid: userDoc.id,
            ...userData,
          },
        };
      })
    );

    if (loadMore) {
      setContractors((prev) => [...prev, ...contractorsList]);
      setFilteredContractors((prev) => [...prev, ...contractorsList]);
    } else {
      setContractors(contractorsList);
      setFilteredContractors(contractorsList);
    }

    setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
    setLoading(false);
  };

  const fetchStats = useCallback(async () => {
    const querySnapshot = await getDocs(collection(db, "stats"));
    const statsData = {};
    querySnapshot.forEach((doc) => {
      statsData[doc.id] = doc.data().count;
    });
    setStats(statsData);
  }, []);

  const filterContractors = useCallback(() => {
    let filteredList = contractors;

    if (selectedCategory) {
      filteredList = filteredList.filter(
        (contractor) => contractor.category === selectedCategory
      );
    }

    if (zipCode) {
      filteredList = filteredList.filter(
        (contractor) => contractor.zipCode === zipCode
      );
    }

    if (selectedState) {
      filteredList = filteredList.filter(
        (contractor) => contractor.state === selectedState
      );
    }

    setFilteredContractors(filteredList);
  }, [contractors, selectedCategory, zipCode, selectedState]);

  const handleSearch = (event) => {
    event.preventDefault();
    filterContractors();
  };

  const handleClearFilters = () => {
    setSelectedCategory("");
    setZipCode("");
    setSelectedState("");
    setFilteredContractors(contractors);
  };

  const handleClearCategory = () => {
    setSelectedCategory("");
  };

  const handleClearZipCode = () => {
    setZipCode("");
  };

  const handleClearState = () => {
    setSelectedState("");
  };

  const handleShowModal = async (contractor) => {
    if (!user) {
      toast.error("You need to be logged in to request a quote.");
      return;
    }

    try {
      // Check if there's an existing submission
      const submissionsQuery = query(
        collection(db, "submissions"),
        where("contractorRef", "==", contractor.userRef),
        where("clientRef", "==", doc(db, "users", user.uid))
      );
      const submissionSnapshot = await getDocs(submissionsQuery);

      if (!submissionSnapshot.empty) {
        // Get the ID of the existing submission
        const existingSubmission = submissionSnapshot.docs[0];
        const submissionId = existingSubmission.id;

        // Redirect to the conversation page (assuming '/conversations' is the route)
        return navigate(`/inbox/${submissionId}`);
      } else {
        // If no submission, proceed to show the modal
        setSelectedContractorListing(contractor);
        setShowModal(true);
      }
    } catch (error) {
      console.error("Error checking previous submissions: ", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedContractorListing(null);
    setFormData({
      requirements: [],
    });
    setQuestions([]);
  };

  const handleFormChange = (e, index, question) => {
    const updatedRequirements = [...formData.requirements];
    updatedRequirements[index] = {
      question: question,
      answer: e.target.value,
    };

    setFormData({
      ...formData,
      requirements: updatedRequirements,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true); // Enable submitting state

    // Check if all questions are answered
    const allQuestionsAnswered = formData.requirements.every(
      (req) => req.answer.trim() !== ""
    );

    if (!allQuestionsAnswered) {
      toast.error("Please answer all the questions.");
      setSubmitting(false);
      return;
    }

    try {
      const payload = {
        listingRef: doc(db, "listings", selectedContractorListing.id),
        contractorRef: doc(db, "users", selectedContractorListing.user.uid),
        clientRef: doc(db, "users", user.uid),
        requirements: formData.requirements, // Include the requirements array
        submittedAt: serverTimestamp(),
      };
      await addDoc(collection(db, "submissions"), payload);
      await sendEmail();
      toast.success("SUCCESS!");
      handleCloseModal();
    } catch (error) {
      console.log("FAILED...", error);
      toast.error("Submission failed. Please try again.");
    }

    setSubmitting(false); // Disable submitting state
  };

  const sendEmail = async () => {
    try {
      const payload = {
        contractor: {
          id: selectedContractorListing.user.uid,
          email: selectedContractorListing.user.email,
        },
        client: {
          id: user.uid,
        },
        message: formData.description,
      };
      await axios.post(
        "https://us-central1-contractorsclearinghouse-7d5aa.cloudfunctions.net/sendEmail",
        payload
      );
    } catch (error) {
      console.log("FAILED...", error);
      toast.error("Email sending failed. Please try again.");
    }
  };

  return (
    <>
      <Header />

      <div className="container mx-auto my-5 px-4">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-3/12">
            <div className="relative m-4 border">
              <p className="text-slate-500 bg-slate-200 mb-5 p-2">
                USA State Map
              </p>

              <div style={{ margin: 0, padding: 0 }}>
                <ComposableMap projection={projection}>
                  <Geographies geography={geoUrl}>
                    {({ geographies }) =>
                      geographies.map((geo) => {
                        const centroid = projection(geoCentroid(geo));
                        const stateName = geo.properties.name;
                        const isSelected = selectedState === stateName;
                        return (
                          <g key={geo.rsmKey}>
                            <Geography
                              geography={geo}
                              onMouseEnter={() => {
                                setTooltipContent(stateName);
                                setTooltipPosition({
                                  x: centroid[0],
                                  y: centroid[1],
                                });
                              }}
                              onMouseLeave={() => {
                                setTooltipContent("");
                              }}
                              onClick={() => setSelectedState(stateName)} // Handle state selection
                              style={{
                                default: {
                                  fill: isSelected ? "#F53" : "#D6D6DA",
                                  outline: "none",
                                  stroke: "#000",
                                  strokeWidth: 0.5,
                                },
                                hover: {
                                  fill: "#F53",
                                  outline: "none",
                                  stroke: "#000",
                                  strokeWidth: 0.5,
                                },
                                pressed: {
                                  fill: "#E42",
                                  outline: "none",
                                  stroke: "#000",
                                  strokeWidth: 0.5,
                                },
                              }}
                            />
                            {tooltipContent === stateName && (
                              <text
                                x={tooltipPosition.x}
                                y={tooltipPosition.y}
                                textAnchor="middle"
                                alignmentBaseline="middle"
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: 12,
                                  pointerEvents: "none",
                                  fill: "#000",
                                  fontWeight: "bold",
                                  textShadow: "0 0 5px #FFF",
                                }}
                              >
                                {stateName}
                              </text>
                            )}
                          </g>
                        );
                      })
                    }
                  </Geographies>
                </ComposableMap>
              </div>
            </div>

            <div className="relative m-4 h-48 overflow-y-scroll custom-scrollbar border p-4">
              {Object.keys(stats).map((state) => (
                <li
                  className="flex items-center justify-between cursor-pointer hover:font-medium"
                  onClick={() => setSelectedState(state)}
                  key={state}
                >
                  <p>{state}</p>
                  <p>{stats[state]}</p>
                </li>
              ))}
            </div>
          </div>

          <div className="w-full lg:w-9/12">
            <div className="search mb-5">
              <form className="flex gap-2" onSubmit={handleSearch}>
                <select
                  className="select-with-arrow border border-slate-500 text-slate-500 p-2 focus:outline-none bg-white"
                  aria-label="Select a Category"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <option value="">Select a Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.category}>
                      {category.category}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  className="border border-slate-500 text-slate-500 p-2 appearance-none w-24 focus:outline-none"
                  placeholder="Zip Code"
                  aria-label="Zip Code"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
                <button
                  type="submit"
                  className="border p-2 bg-slate-600 hover:bg-slate-700 text-white appearance-none focus:outline-none"
                >
                  Search
                </button>
              </form>
            </div>

            <div className="mb-5">
              {selectedCategory && (
                <div className="mb-2">
                  <span className="font-bold">Category:</span>{" "}
                  {selectedCategory}
                  <button
                    onClick={handleClearCategory}
                    className="ml-2 text-red-600"
                  >
                    Clear
                  </button>
                </div>
              )}
              {zipCode && (
                <div className="mb-2">
                  <span className="font-bold">Zip Code:</span> {zipCode}
                  <button
                    onClick={handleClearZipCode}
                    className="ml-2 text-red-600"
                  >
                    Clear
                  </button>
                </div>
              )}
              {selectedState && (
                <div className="mb-2">
                  <span className="font-bold">State:</span> {selectedState}
                  <button
                    onClick={handleClearState}
                    className="ml-2 text-red-600"
                  >
                    Clear
                  </button>
                </div>
              )}
              {(selectedCategory || zipCode || selectedState) && (
                <button
                  onClick={handleClearFilters}
                  className="border p-2 bg-red-600 text-white appearance-none focus:outline-none"
                >
                  Clear All Filters
                </button>
              )}
            </div>

            {filteredContractors.map((item) => (
              <div
                key={item.id}
                className="border border-slate-400 mb-5 p-4 hover:border-black rounded-sm"
              >
                <div className="flex justify-between">
                  <div>
                    <p className="text-2xl mb-5">{item.name}</p>
                    <p className="mb-5 font-thin italic">{item.description}</p>

                    <div className="flex gap-5">
                      {item?.images &&
                        item?.images.map((img) => (
                          <img
                            src={img}
                            width={200}
                            className="mb-5 rounded-md"
                          />
                        ))}
                    </div>

                    <p>State: {item.state}</p>
                    <p>Category: {item.category}</p>
                  </div>
                </div>

                <div>
                  {user && userData.role === "client" && (
                    <button
                      type="submit"
                      className="border border-black p-2 text-black mt-5 hover:bg-black hover:text-white"
                      onClick={() => handleShowModal(item)}
                    >
                      Get Quote
                    </button>
                  )}

                  {!user && (
                    <button
                      onClick={() =>
                        toast.error(
                          "You need to be logged in to request a quote."
                        )
                      }
                      className="border border-black p-2 text-black mt-5 hover:bg-black hover:text-white"
                    >
                      Log in to Get Quote
                    </button>
                  )}

                </div>
              </div>
            ))}

            {loading && <Spinner />}
            {!loading && lastDoc && (
              <button
                onClick={() => fetchContractors(true)}
                className="border bg-slate-200 text-slate-500 appearance-none focus:outline-none text-sm p-3"
              >
                Load More
              </button>
            )}
          </div>
        </div>
      </div>

      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form
                onSubmit={handleFormSubmit}
                className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
              >
                <div>
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-title"
                  >
                    Get a Quote from {selectedContractorListing.name}
                  </h3>
                  {/* Render additional category-specific questions */}
                  <div className="mt-8">
                    {renderCategorySpecificQuestions}
                  </div>
                </div>
                <div className="mt-4 sm:mt-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    disabled={submitting}
                    className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 ${submitting ? "bg-gray-500" : "bg-green-600"
                      } text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm`}
                  >
                    {submitting ? "Sending..." : "Send Request"}
                  </button>

                  <button
                    type="button"
                    onClick={handleCloseModal}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
}

export default Home;
