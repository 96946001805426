import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Home from "./Home";
import SubmitListing from "./SubmitListing";
import Signup from "./Signup";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import PrivateRoute from "./PrivateRoute";
import { AuthProvider } from "./AuthProvider";
import Listings from "./admin/Listing";
import ListingDetails from "./admin/ListingDetails";
import Inbox from "./Inbox";
import InboxById from "./inboxById";
import AdminLayout from "./admin/AdminLayout";
import Chats from "./admin/Chats";
import ViewChat from "./admin/ViewChat";
import Dashboard from "./admin/Dashboard";
import AdminRoute from "./AdminRoute";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";
import ReferralsList from "./ReferralsList";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route 
            path="/my-referrals" 
            element={
              <PrivateRoute>
                <ReferralsList />
              </PrivateRoute>
            } 
          />
          <Route
            path="/submit-listing"
            element={
              <PrivateRoute>
                <SubmitListing />
              </PrivateRoute>
            }
          />
          <Route
            path="/inbox"
            element={
              <PrivateRoute>
                <Inbox />
              </PrivateRoute>
            }
          />

          <Route
            path="/inbox/:id"
            element={
              <PrivateRoute>
                <InboxById />
              </PrivateRoute>
            }
          />

          <Route
            path="/admin"
            element={
              <AdminRoute>
                <AdminLayout />
              </AdminRoute>
            }
          >
            <Route
              path="listing"
              element={
                <AdminRoute>
                  <Listings />
                </AdminRoute>
              }
            />
            <Route
              path="listing/:id"
              element={
                <AdminRoute>
                  <ListingDetails />
                </AdminRoute>
              }
            />

            <Route
              path="chats"
              element={
                <AdminRoute>
                  <Chats />
                </AdminRoute>
              }
            />

            <Route
              path="chats/:id"
              element={
                <AdminRoute>
                  <ViewChat />
                </AdminRoute>
              }
            />

            <Route
              path="dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
