import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase"; // Adjust the path to your Firebase config

const fetchClientSubmissions = async (clientId) => {
  const contractorRef = doc(db, "users", clientId);
  const q = query(
    collection(db, "submissions"),
    where("contractorRef", "==", contractorRef),
    orderBy("submittedAt", "desc")
  );

  const querySnapshot = await getDocs(q);
  const submissions = await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const data = { ...doc.data(), id: doc.id };
      const clientSnapshot = await getDoc(data.clientRef); // Fetch contractor document
      const clientDetails = clientSnapshot.exists()
        ? clientSnapshot.data()
        : null;

      return {
        ...data,
        data: {
          ...clientDetails,
        },
      };
    })
  );

  return submissions; // Array of submissions made by this client, with contractor details included
};

export default fetchClientSubmissions;
