import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { db } from './../firebase';
import { collection, onSnapshot } from 'firebase/firestore';

const Listings = () => {
  const [listings, setListings] = useState([]);

  useEffect(() => {
    const fetchListings = () => {
      const listingsRef = collection(db, 'listings');
      const unsubscribe = onSnapshot(
        listingsRef,
        (snapshot) => {
          setListings(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        },
        (error) => {
          console.error('Error fetching listings:', error);
        }
      );

      return () => unsubscribe(); // Cleanup subscription on unmount
    };

    fetchListings();
  }, []);

  const renderTableRow = useCallback((listing) => (
    <tr key={listing.id}>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        <p className="text-gray-900 whitespace-no-wrap">{listing.name}</p>
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        <p className="text-gray-900 whitespace-no-wrap">{listing.status}</p>
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        <Link to={`/admin/listing/${listing.id}`} className="text-indigo-600 hover:text-indigo-900">
          View Details
        </Link>
      </td>
    </tr>
  ), []);

  return (
    <div className="container">
      <div>
        <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
          <h2 className="text-2xl leading-tight">Listings</h2>
        </div>
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    View
                  </th>
                </tr>
              </thead>
              <tbody>
                {listings.map(renderTableRow)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listings;
