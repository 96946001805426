// src/PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";

function AdminRoute({ children }) {
  const { userData } = useAuth();

  return userData && userData.role === "admin" ? children : <Navigate to="/" />;
}

export default AdminRoute;
