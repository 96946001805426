import { Link } from "react-router-dom"

const Footer = () => {
    return (
        <>
            <footer className="bg-slate-200 py-5 px-4">
                <div className="container mx-auto">
                    <div className="flex justify-between flex-wrap">
                        <p>&copy; Copyright ContractorsClearingHouse</p>
                        <ul className="flex gap-10 text-sm">
                            <li>
                                <Link to="/terms-of-service">Terms of Service</Link>
                            </li>
                            <li>
                                <Link to="/privacy-policy">Privacy Policy</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;