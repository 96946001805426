import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import { useEffect, useState } from "react";
import Header from "./components/Header";
import fetchContractorSubmissions from "./hooks/fetchContractorSubmissions";
import { Link, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import fetchClientSubmissions from "./hooks/fetchClientSubmissions";
import { useAuth } from "./AuthProvider";
import Spinner from "./components/Spinner"; // Assume you have a Spinner component

export const ViewAsClient = ({ selectedInboxId, onSelectInbox }) => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    if (user) {
      fetchContractorSubmissions(user.uid)
        .then((data) => {
          setRecords(data);
        })
        .catch((error) => {
          console.error("Error fetching contractor submissions:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false after data is fetched
        });
    }
  }, [user]);

  if (loading) {
    return <Spinner />; // Display loading spinner while fetching data
  }

  return (
    <div>
      <p className="text-3xl">Contractors</p>
      <p className="text-slate-400">
        List of Contractors You've Requested Quotes
      </p>

      <div className="my-5">
        {records.map((item) => (
          <div
            key={item.id}
            onClick={() => {
              onSelectInbox(item.id);
              navigate(`/inbox/${item.id}`);
            }} // Update the selected item ID when clicked
            className={`border border-dotted border-slate-500 p-4 rounded-lg cursor-pointer my-5 ${selectedInboxId === item.id ? "bg-blue-100" : "" // Apply highlight if item is selected
              }`}
          >
            <div className="flex justify-between items-center">
              <p className="text-2xl font-bold">
                {item.data?.contractor.name}
              </p>
              <p className="text-sm text-slate-500">
                {format(
                  item.submittedAt.toDate
                    ? item.submittedAt.toDate()
                    : new Date(),
                  "Pp"
                )}
              </p>
            </div>

            <p className="text-sm text-slate-500 mt-3">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export const ViewAsContractor = ({ selectedInboxId, onSelectInbox }) => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    if (user) {
      fetchClientSubmissions(user.uid)
        .then((data) => {
          setRecords(data);
        })
        .catch((error) => {
          console.error("Error fetching client submissions:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false after data is fetched
        });
    }
  }, [user]);

  if (loading) {
    return <Spinner />; // Display loading spinner while fetching data
  }

  return (
    <div>
      <p className="text-3xl">Clients</p>
      <p className="text-slate-400">List of Clients They've Requested Quotes</p>

      <div className="my-5">
        {records.map((item) => (
          <div
            key={item.id}
            onClick={() => {
              onSelectInbox(item.id);
              navigate(`/inbox/${item.id}`);
            }} // Update the selected item ID when clicked
            className={`border border-dotted border-slate-500 p-4 rounded-lg cursor-pointer my-5 ${selectedInboxId === item.id ? "bg-blue-100" : "" // Apply highlight if item is selected
              }`}
          >

            <div className="flex justify-between items-center">
              <p className="text-2xl font-bold">{item.data?.fullName}</p>
              <p className="text-sm text-slate-500">
                {format(
                  item.submittedAt.toDate
                    ? item.submittedAt.toDate()
                    : new Date(),
                  "Pp"
                )}
              </p>
            </div>

            <p className="text-sm text-slate-500 mt-3">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const Inbox = () => {
  const { userData: user } = useAuth();
  const [selectedInboxId, setSelectedInboxId] = useState(null); // State to keep track of the selected inbox item ID

  const handleSelectInbox = (id) => {
    setSelectedInboxId(id);
  };

  return (
    <>
      <Header />
      <div className="container mx-auto my-5 px-4">
        {user.role === "contractor" && (
          <ViewAsContractor
            selectedInboxId={selectedInboxId}
            onSelectInbox={handleSelectInbox}
          />
        )}
        {user.role === "client" && (
          <ViewAsClient
            selectedInboxId={selectedInboxId}
            onSelectInbox={handleSelectInbox}
          />
        )}
      </div>
    </>
  );
};

export default Inbox;
