import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebase";
import { ClipLoader } from "react-spinners";
import { format } from "date-fns";

const Chats = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const q = query(
          collection(db, "submissions"),
          orderBy("submittedAt", "desc")
        );
        const querySnapshot = await getDocs(q);
        setItems(
          querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      } catch (error) {
        console.error("Error fetching submissions: ", error);
        setError("Failed to fetch submissions. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, []);

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  if (error)
    return <div className="text-center text-red-500 mt-4">{error}</div>;

  return (
    <div>
      {items.map((item) => (
        <Link
          key={item.id}
          to={`/admin/chats/${item.id}`}
          className="block p-4 mb-4 bg-white shadow rounded hover:bg-gray-100 transition duration-200"
        >
          <div className="flex justify-between items-center">
            <span>{item.id}</span>
            <span className="text-sm text-slate-500">
              {format(
                item.submittedAt.toDate ? item.submittedAt.toDate() : new Date(),
                "Pp"
              )}
            </span>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Chats;
