import {
  collection,
  query,
  where,
  getDoc,
  doc,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase"; // Adjust the path to your Firebase config

const fetchContractorSubmissions = async (contractorId) => {
  const clientRef = doc(db, "users", contractorId);

  const q = query(
    collection(db, "submissions"),
    where("clientRef", "==", clientRef),
    orderBy("submittedAt", "desc")
  );

  const querySnapshot = await getDocs(q);
  const submissions = await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const data = { ...doc.data(), id: doc.id };
      const contractorSnapshot = await getDoc(data.contractorRef); // Fetch contractor document
      const contractorDetails = contractorSnapshot.exists()
        ? contractorSnapshot.data()
        : null;

      const contractorListingSnapshot = await getDoc(data.listingRef); // Fetch contractor document
      const contractorListDetails = contractorListingSnapshot.exists()
        ? contractorListingSnapshot.data()
        : null;

      return {
        ...data,
        data: {
          ...contractorDetails,
          contractor: contractorListDetails,
        },
      };
    })
  );

  return submissions; // Array of submissions sent to this contractor, with client details included
};

export default fetchContractorSubmissions;
