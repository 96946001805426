import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  addDoc,
  onSnapshot,
  updateDoc,
  serverTimestamp,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase"; // Assuming you have set up Firebase auth
import Header from "./components/Header";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners"; // Example loader, you can use any loader component
import { format } from "date-fns"; // Importing date-fns for formatting dates
import Modal from "react-modal";
import { ViewAsClient, ViewAsContractor } from "./Inbox";
import { useAuth } from "./AuthProvider";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

const Chat = () => {
  const { id } = useParams(); // This is the chatId or inboxId
  const [selectedInboxId, setSelectedInboxId] = useState(id || null); // State to keep track of the selected inbox item ID
  const { userData: user } = useAuth(); // Get the current logged-in user
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [quote, setQuote] = useState({ amount: "" });
  const [submission, setSubmission] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false); // Loader for sending messages
  const [showPaymentModal, setShowPaymentModal] = useState(false); // New state for payment modal
  const [showQuoteModal, setShowQuoteModal] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState(null); // State to track the selected quote
  const messagesEndRef = useRef(null); // To scroll to the latest message

  const handleSelectInbox = (id) => {
    setSelectedInboxId(id);
  };

  useEffect(() => {
    const fetchSubmissionById = async () => {
      try {
        setLoading(true);

        // Fetch the submission document by ID from Firestore
        const docRef = doc(db, "submissions", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setSubmission(docSnap.data());
        } else {
          toast.error("No such submission found!");
        }
      } catch (error) {
        console.error("Error fetching submission:", error);
        toast.error("Failed to fetch submission. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissionById();
  }, [id]);

  useEffect(() => {
    const fetchMessages = () => {
      const messagesRef = query(
        collection(db, "chats", id, "messages"),
        orderBy("createdAt", "asc") // Order messages by createdAt in ascending order
      );
      return onSnapshot(messagesRef, (snapshot) => {
        const msgs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMessages(msgs);
        scrollToBottom(); // Scroll to the latest message
      });
    };

    fetchMessages();
  }, [id]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const sendMessage = async () => {
    if (newMessage.trim() === "") return;

    setSending(true); // Start sending loader

    const messageRef = collection(db, "chats", id, "messages");
    await addDoc(messageRef, {
      senderId: user.uid,
      message: newMessage,
      createdAt: serverTimestamp(),
    });

    setNewMessage("");
    setSending(false); // Stop sending loader
  };

  const sendQuote = async (quoteDetails) => {
    // Validate required fields
    if (!quoteDetails.amount || !quoteDetails.description) {
      toast.error("Both amount and description are required to send a quote."); // Display an alert or handle error accordingly
      return; // Exit the function if validation fails
    }

    setSending(true); // Start sending loader

    const messageRef = collection(db, "chats", id, "messages");
    await addDoc(messageRef, {
      senderId: user.uid,
      message: "Sent a quote",
      quote: quoteDetails,
      quoteStatus: "sent",
      createdAt: serverTimestamp(),
    });

    setQuote({ amount: "" }); // Clear the quote input field
    setShowQuoteModal(false); // Close the quote modal after submission
    setSending(false); // Stop sending loader
  };

  const withdrawQuote = async (messageId) => {
    const messageRef = doc(db, "chats", id, "messages", messageId);
    await updateDoc(messageRef, {
      quoteStatus: "withdrawn",
    });

    setQuote(null);
  };

  const openPaymentModal = (messageId) => {
    setSelectedMessageId(messageId);
    setShowPaymentModal(true);
  };

  const handlePayment = async () => {
    // Simulate payment processing
    setSending(true);

    setTimeout(async () => {
      // After payment success
      await updateDoc(doc(db, "chats", id, "messages", selectedMessageId), {
        quoteStatus: "paid",
      });

      toast.success("Payment successful!");
      setShowPaymentModal(false);
      setSending(false);
    }, 2000); // Simulate a delay for payment processing
  };

  const rejectQuote = async (messageId) => {
    const messageRef = doc(db, "chats", id, "messages", messageId);
    await updateDoc(messageRef, {
      quoteStatus: "rejected",
    });

    setQuote(null);
  };

  return (
    <>
      <Header />

      <div className="container mx-auto my-5 px-4 flex flex-wrap lg:flex-nowrap">
        <div className="w-full lg:w-1/3 lg:pr-4">
          {user.role === "contractor" && (
            <ViewAsContractor
              selectedInboxId={selectedInboxId}
              onSelectInbox={handleSelectInbox}
            />
          )}
          {user.role === "client" && (
            <ViewAsClient
              selectedInboxId={selectedInboxId}
              onSelectInbox={handleSelectInbox}
            />
          )}
        </div>

        <div className="w-full lg:w-2/3 lg:pl-4">

          {loading ? (
            <div className="flex justify-center items-center h-32">
              <ClipLoader size={50} color={"#123abc"} loading={loading} />
            </div>
          ) : submission ? (
            <div className="border border-slate-400 mb-5 p-4 rounded-sm">
              <h2 className="text-2xl mb-5">Project Details</h2>
              {submission.requirements.map((item) => (
                <div className="mb-5">
                  <p className="font-semibold">{item.question}</p>
                  <p>{item.answer}</p>
                </div>
              ))}
            </div>
          ) : (
            <p>No submission found with the provided ID.</p>
          )}


          <div className="container mx-auto my-5">

            {loading === false && (
              <div className="chat-container border border-slate-400 p-4 mb-5">
                <div className="messages-list max-h-96 overflow-y-auto">
                  {messages.map((msg) => (
                    <div key={msg.id} className="message mb-3">
                      <p>
                        <strong>
                          {msg.senderId === user.uid ? "You" : msg.senderId === submission?.contractorRef?.id ? "Contractor" : "Client"}
                        </strong>
                        : {msg.message}
                      </p>
                      {msg.createdAt && (
                        <p className="text-xs text-gray-500">
                          {format(
                            msg.createdAt.toDate ? msg.createdAt.toDate() : new Date(),
                            "Pp"
                          )}
                        </p>
                      )}
                      {msg.quote && (
                        <div className="quote-details mt-2 p-2 border border-slate-400 rounded">
                          <p>Quote: ${msg.quote.amount}</p>
                          <p>What it is included: {msg.quote.description}</p>
                          <p>Status: {msg.quoteStatus}</p>
                          {msg.senderId !== user.uid &&
                            msg.quoteStatus === "sent" && (
                              <div className="quote-actions flex space-x-2 mt-2">
                                <button
                                  onClick={() => openPaymentModal(msg.id)}
                                  className="bg-green-500 text-white px-3 py-1 rounded"
                                >
                                  Accept
                                </button>
                                <button
                                  onClick={() => rejectQuote(msg.id)}
                                  className="bg-red-500 text-white px-3 py-1 rounded"
                                >
                                  Reject
                                </button>
                              </div>
                            )}
                          {msg.senderId === user.uid &&
                            msg.quoteStatus === "sent" && (
                              <button
                                onClick={() => withdrawQuote(msg.id)}
                                className="bg-yellow-500 text-white px-3 py-1 rounded mt-2"
                              >
                                Withdraw Quote
                              </button>
                            )}
                        </div>
                      )}
                    </div>
                  ))}
                  <div ref={messagesEndRef} />
                </div>

                <div className="flex message-input mt-5 gap-2">
                  <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type your message..."
                    className="border border-slate-400 p-2 w-full"
                  />
                  <button
                    onClick={sendMessage}
                    className="bg-blue-500 text-white p-2"
                    disabled={sending}
                  >
                    {sending ? "Sending..." : "Send"}
                  </button>
                </div>
              </div>
            )}

            {/* Only contractor can send a quote */}
            {loading === false &&
              user &&
              user.uid === submission?.contractorRef.id && (
                <div className="quote-section mt-5">
                  <button
                    onClick={() => setShowQuoteModal(true)}  // Update to show modal
                    className="bg-gray-500 text-white px-3 py-2 rounded"
                  >
                    Create Quote
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>

      {/* Payment Modal */}
      <Modal
        isOpen={showPaymentModal}
        onRequestClose={() => setShowPaymentModal(false)}
        contentLabel="Payment Modal"
        style={customStyles}
      >
        <h2>Complete Payment</h2>
        <p>To accept this quote, please proceed with the payment.</p>
        <div className="flex gap-5 items-center mt-5">
          <button
            onClick={handlePayment}
            className="bg-green-500 text-white px-3 py-1 rounded"
            disabled={sending}
          >
            {sending ? "Processing..." : "Pay Now"}
          </button>
          <button
            onClick={() => setShowPaymentModal(false)}
            className="bg-gray-500 text-white px-3 py-1 rounded"
          >
            Cancel
          </button>
        </div>
      </Modal>

      {/* Send Quote Modal */}
      <Modal
        isOpen={showQuoteModal}
        onRequestClose={() => setShowQuoteModal(false)}
        contentLabel="Send Quote Modal"
        style={customStyles}
      >
        <div className="flex justify-between items-center">
          <h2>Send a Quote</h2>
          <button
            onClick={() => setShowQuoteModal(false)}
            className="bg-slate-200 text-slate-400 px-3 py-1 rounded text-sm"
          >
            Close
          </button>
        </div>
        <div className="mt-4">
          <input
            type="number"
            placeholder="Enter quote amount"
            value={quote.amount}
            onChange={(e) => setQuote({ ...quote, amount: e.target.value })}
            className="border border-slate-400 p-2 w-full"
          />

          <textarea
            placeholder="Enter quote description (e.g., list of services included)"
            value={quote.description}
            onChange={(e) => setQuote({ ...quote, description: e.target.value })}
            className="border border-slate-400 p-2 w-full mt-2"
            rows="4"
          />

          <button
            onClick={() => sendQuote(quote)}
            className="bg-green-500 text-white p-2 mt-2"
            disabled={sending}
          >
            {sending ? "Sending..." : "Send Quote"}
          </button>
        </div>
      </Modal>

    </>
  );
};

export default Chat;
