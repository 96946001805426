import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { db, storage } from "./../firebase"; // Ensure Firebase Storage is initialized in your firebase.js
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useParams, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./../components/Header";
import states from "./../data/usa-states.json";
import categories from "./../data/categories.json";

function ListingDetails() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [state, setState] = useState("");
  const [townCity, setTownCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [status, setStatus] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const docRef = doc(db, "listings", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setName(data.name);
          setPhone(data.phone);
          setDescription(data.description);
          setCategory(data.category);
          setState(data.state);
          setTownCity(data.townCity);
          setZipcode(data.zipcode);
          setExistingImages(data.images || []);
          setUser(data.user);
          setStatus(data.status || "");
        } else {
          toast.error("Listing not found");
          navigate("/");
        }
      } catch (error) {
        console.error("Error fetching listing: ", error);
        toast.error("Error fetching listing");
      } finally {
        setLoading(false);
      }
    };

    fetchListing();
  }, [id, navigate]);

  const onDrop = useCallback((acceptedFiles) => {
    const filteredFiles = acceptedFiles.filter((file) =>
      file.type.startsWith("image/")
    );
    if (filteredFiles.length !== acceptedFiles.length) {
      toast.error("Only images are allowed");
    }
    setFiles((prevFiles) =>
      prevFiles.concat(
        filteredFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  const removeFile = (file) => () => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
    URL.revokeObjectURL(file.preview);
  };

  const validateForm = () => {
    if (!name.trim()) {
      toast.error("Name is required");
      return false;
    }
    if (!phone.trim()) {
      toast.error("Phone is required");
      return false;
    }
    if (!description.trim()) {
      toast.error("Description is required");
      return false;
    }
    if (!category.trim()) {
      toast.error("Category is required");
      return false;
    }
    if (!state.trim()) {
      toast.error("State is required");
      return false;
    }
    if (!townCity.trim()) {
      toast.error("Town/City is required");
      return false;
    }
    if (!zipcode.trim()) {
      toast.error("Zipcode is required");
      return false;
    }
    return true;
  };

  const uploadImages = async () => {
    const uploadPromises = files.map((file) => {
      const storageRef = ref(
        storage,
        `portfolioImages/${user.id}/${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          null,
          (error) => reject(error),
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          }
        );
      });
    });

    return Promise.all(uploadPromises);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setSubmitting(true);
      try {
        const newImageUrls = await uploadImages();

        const formData = {
          name,
          phone,
          description,
          category,
          state,
          townCity,
          zipcode,
          images: [...existingImages, ...newImageUrls],
          status,
        };

        const docRef = doc(db, "listings", id);
        await updateDoc(docRef, formData);

        toast.success("Listing updated successfully");
        setFiles([]);
      } catch (error) {
        console.error("Error updating listing: ", error);
        toast.error("Error updating listing: " + error.message);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleStatusChange = async (newStatus) => {
    try {
      const docRef = doc(db, "listings", id);
      await updateDoc(docRef, { status: newStatus });
      setStatus(newStatus);
      toast.success(`Listing ${newStatus}`);
    } catch (error) {
      console.error(`Error updating listing status to ${newStatus}: `, error);
      toast.error(`Error updating listing status: ${error.message}`);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="flex items-center justify-center min-h-screen my-10">
        <div className="bg-white p-8 rounded-lg w-full max-w-md">
          <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">
            Listing Details
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <TextInput
              label="Company Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={submitting}
            />
            <TextInput
              label="Company Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              disabled={submitting}
            />
            <TextArea
              label="Company Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              disabled={submitting}
            />
            <SelectInput
              label="Business Category"
              value={category}
              options={categories.map((c) => ({
                value: c.category,
                label: c.category,
              }))}
              onChange={(e) => setCategory(e.target.value)}
              disabled={submitting}
            />
            <SelectInput
              label="State"
              value={state}
              options={states.map((s) => ({
                value: s.name,
                label: s.name,
              }))}
              onChange={(e) => setState(e.target.value)}
              disabled={submitting}
            />
            <TextInput
              label="Town/City"
              value={townCity}
              onChange={(e) => setTownCity(e.target.value)}
              disabled={submitting}
            />
            <TextInput
              label="Zipcode"
              value={zipcode}
              onChange={(e) => setZipcode(e.target.value)}
              disabled={submitting}
            />

            <div
              {...getRootProps()}
              className={`border-dashed border-2 border-gray-300 p-4 text-center mt-0 ${
                submitting ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <input
                {...getInputProps()}
                className="my-0"
                disabled={submitting}
              />
              {isDragActive ? (
                <p>Drop the portfolio images here ...</p>
              ) : (
                <p>
                  Drag 'n' drop some portfolio images here, or click to select
                  images
                </p>
              )}
            </div>
            {files.length > 0 && (
              <ul className="list-disc list-inside">
                {files.map((file) => (
                  <li
                    key={file.path}
                    className="flex justify-between items-center"
                  >
                    <img
                      src={file.preview}
                      alt={file.name}
                      className="h-20 w-20 object-cover"
                    />
                    <button
                      type="button"
                      onClick={removeFile(file)}
                      className="ml-4 bg-red-500 text-white px-2 py-1 rounded"
                      disabled={submitting}
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            )}
            {existingImages.length > 0 && (
              <ul className="list-disc list-inside">
                {existingImages.map((url, index) => (
                  <li key={index} className="flex justify-between items-center">
                    <img
                      src={url}
                      alt={`Existing ${index}`}
                      className="h-20 w-20 object-cover"
                    />
                  </li>
                ))}
              </ul>
            )}
            <div className="flex space-x-4">
              <button
                type="button"
                onClick={() => handleStatusChange("approved")}
                className="w-full bg-green-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                disabled={submitting}
              >
                Approve
              </button>
              <button
                type="button"
                onClick={() => handleStatusChange("rejected")}
                className="w-full bg-red-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                disabled={submitting}
              >
                Reject
              </button>
              <button
                type="button"
                onClick={() => handleStatusChange("trash")}
                className="w-full bg-gray-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                disabled={submitting}
              >
                Move to Trash
              </button>
            </div>
            <button
              type="submit"
              className={`w-full bg-indigo-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                submitting ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={submitting}
            >
              {submitting ? (
                <svg
                  className="animate-spin h-5 w-5 text-white mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
              ) : (
                "Update Listing"
              )}
            </button>
          </form>
          <ToastContainer />
        </div>
      </div>
    </>
  );
}

const TextInput = ({ label, value, onChange, disabled }) => (
  <div>
    <label className="block text-sm font-medium text-gray-700">{label}</label>
    <input
      type="text"
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
        disabled ? "bg-gray-100" : "bg-white"
      }`}
    />
  </div>
);

const TextArea = ({ label, value, onChange, disabled }) => (
  <div>
    <label className="block text-sm font-medium text-gray-700">{label}</label>
    <textarea
      value={value}
      onChange={onChange}
      rows="4"
      disabled={disabled}
      className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
        disabled ? "bg-gray-100" : "bg-white"
      }`}
    ></textarea>
  </div>
);

const SelectInput = ({ label, value, options, onChange, disabled }) => (
  <div>
    <label className="block text-sm font-medium text-gray-700">{label}</label>
    <select
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
        disabled ? "bg-gray-100" : "bg-white"
      }`}
    >
      <option value="">Please select from dropdown</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ListingDetails;
