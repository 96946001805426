import React, { useEffect, useState } from "react";
import { auth, db } from "./firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import PropTypes from "prop-types";

function Signup() {
  const navigate = useNavigate();
  const location = useLocation();
  const [fullName, setFullName] = useState(""); // New state for full name
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(""); // No default role selected
  const [answers, setAnswers] = useState({}); // Store answers as an object
  const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);
  const [referer, setReferer] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const refererParam = params.get('referer') || localStorage.getItem('referer');

    if (refererParam) {
      setReferer(refererParam);
    }
  }, [location.search]);

  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!acceptPrivacyPolicy) {
      toast.error("You must accept the privacy policy to continue.");
      setLoading(false);
      return;
    }

    if (role === "") {
      toast.error("Please select a role.");
      setLoading(false);
      return;
    }

    const questions =
      role === "client" ? questionsData.client : questionsData.contractor;
    const unansweredQuestions = questions.filter(
      (question) => !answers[question]
    );

    if (unansweredQuestions.length > 0) {
      toast.error("Please answer all questions.");
      setLoading(false);
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Add user data to Firestore
      await setDoc(doc(db, "users", user.uid), {
        fullName, // Include full name
        email: user.email,
        uid: user.uid,
        role,
        answers,
        createdAt: new Date(),
      });


      // If there is a referrer, update the referrer's referral count
      if (referer) {
        const referrerDocRef = doc(db, "users", referer);

        // Fetch the referrer document
        const referrerDoc = await getDoc(referrerDocRef);

        if (referrerDoc.exists()) {
          const newReferralCount = (referrerDoc.data().referralCount || 0) + 1;
          const newReferralCoins = (referrerDoc.data().referralCoins || 0) + 100;

          // Update the referrer document with the new referral count
          await updateDoc(referrerDocRef, { referralCount: newReferralCount, referralCoins: newReferralCoins });

          // Add a new entry in the referrals collection
          await setDoc(doc(db, "referrals", `${referer}_${user.uid}`), {
            referrerId: referer,
            referredUserId: user.uid,
            timestamp: new Date(),
          });
        }
      }

      toast.success("User created successfully");
      navigate("/login");
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const questionsData = {
    client: [
      "Would you like our Advisor VIP Package where an experienced advisor reviews the project during the process or at completion?",
      "Would you like an alarm system?",
      "Would you like to reduce or get homeowners or mortgage insurance today?",
      "Would you like a realtor to list a property?",
      "Do you need a mortgage broker or a hard money lender?",
      "Would you like a FREE newsletter with updates on free funding and city grants for homeowners?",
    ],
    contractor: [
      "Do you need or would like to reduce business insurance?",
      "Would you like a virtual assistant ($500 mthly) to answer and make calls for the business?",
      "Do you need or want a bookkeeper to help maintain your taxes?",
      "Do you want funding to boost your business?",
      "Do you want to join the VIP package so you can receive leads first?",
      "Do you need a website?",
      "Do you need a card processor or would like to reduce your card processing rates?",
    ],
  };

  const handleAnswerChange = (question, answer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [question]: answer,
    }));
  };

  return (
    <>
      <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
        <div className="bg-white p-8 shadow-md w-full max-w-md">
          <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">
            Signup
          </h2>
          <form onSubmit={handleSignup} className="space-y-6">
            <TextInput
              label="Full Name" // New input for full name
              type="text"
              id="fullName"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Full Name"
              disabled={loading}
            />
            <TextInput
              label="Email"
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              disabled={loading}
            />
            <TextInput
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              disabled={loading}
            />
            <RoleRadio
              label="Role"
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              disabled={loading}
            />

            {role && (
              <Questions
                questions={questionsData[role]}
                answers={answers}
                handleAnswerChange={handleAnswerChange}
                disabled={loading}
              />
            )}

            <div className="flex items-center">
              <input
                type="checkbox"
                id="acceptPrivacyPolicy"
                checked={acceptPrivacyPolicy}
                onChange={(e) => setAcceptPrivacyPolicy(e.target.checked)}
                className="form-checkbox"
                disabled={loading}
              />
              <label
                htmlFor="acceptPrivacyPolicy"
                className="ml-2 block text-sm font-medium text-gray-700"
              >
                I accept the <Link to="/privacy-policy" target="_blank" className="text-blue-500">privacy policy</Link> and <Link to="/terms-of-service" className="text-blue-500" target="_blank">terms of services</Link>
              </label>
            </div>
            <button
              type="submit"
              className={`w-full bg-blue-600 text-white py-2 rounded-none shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              disabled={loading}
            >
              {loading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
              ) : (
                "Signup"
              )}
            </button>
          </form>
          <p className="mt-4 text-center">
            Already have an account?{" "}
            <Link to="/login" className="text-blue-700">
              Sign in
            </Link>
          </p>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

const TextInput = ({
  label,
  type,
  id,
  value,
  onChange,
  placeholder,
  disabled,
}) => (
  <div>
    <label htmlFor={id} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    <input
      type={type}
      id={id}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${disabled ? "bg-gray-100" : "bg-white"
        }`}
    />
  </div>
);

const RoleRadio = ({ label, id, value, onChange, disabled }) => (
  <div>
    <span className="block text-sm font-medium text-gray-700">{label}</span>
    <div className="mt-2 space-y-4">
      <label className="flex items-center">
        <input
          type="radio"
          id={`${id}-client`}
          value="client"
          checked={value === "client"}
          onChange={onChange}
          className="form-radio"
          disabled={disabled}
        />
        <span className="ml-2">Client or Agent</span>
      </label>
      <label className="flex items-center">
        <input
          type="radio"
          id={`${id}-contractor`}
          value="contractor"
          checked={value === "contractor"}
          onChange={onChange}
          className="form-radio"
          disabled={disabled}
        />
        <span className="ml-2">Contractor</span>
      </label>
    </div>
  </div>
);

const Questions = ({ questions, answers, handleAnswerChange, disabled }) => (
  <div>
    {questions.map((question, index) => (
      <div key={index} className="mb-5">
        <span className="block text-sm font-medium text-gray-700">
          {question}
        </span>
        <div className="mt-2 space-y-4">
          <label className="flex items-center">
            <input
              type="radio"
              name={question}
              value="yes"
              checked={answers[question] === "yes"}
              onChange={(e) => handleAnswerChange(question, e.target.value)}
              className="form-radio"
              disabled={disabled}
            />
            <span className="ml-2">Yes</span>
          </label>
          <label className="flex items-center">
            <input
              type="radio"
              name={question}
              value="no"
              checked={answers[question] === "no"}
              onChange={(e) => handleAnswerChange(question, e.target.value)}
              className="form-radio"
              disabled={disabled}
            />
            <span className="ml-2">No</span>
          </label>
        </div>
      </div>
    ))}
  </div>
);

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

RoleRadio.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

Questions.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.string).isRequired,
  answers: PropTypes.object.isRequired,
  handleAnswerChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Signup;
