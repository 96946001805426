import logo from "../logo.png";
import { useEffect, useState } from "react";
import { auth } from "../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { Link, useLocation, useParams } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import { RiDiscountPercentFill } from "react-icons/ri";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

const Header = () => {
  const referer = localStorage.getItem('referer');
  const [showRefererModal, setShowRefererModal] = useState(false);

  const { currentUser } = useAuth();

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  return (
    <>
      <header className="bg-white py-2 border-b">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center justify-between">
            <div className="w-full lg:w-4/12">
              <Link to="/">
                <img src={logo} width={150} alt="Logo" />
              </Link>
            </div>

            <div className="w-full lg:w-8/12">
              <div className="flex flex-wrap flex-col lg:flex-row items-start justify-end gap-4 my-10 lg:my-0">
                {currentUser ? (
                  <>
                    <Link to="/inbox" className="hover:underline">
                      Inbox
                    </Link>

                    <Link to="/submit-listing" className="hover:underline">
                      Submit Listing
                    </Link>

                    <button className="text-blue-900 hover:underline rounded-md" onClick={() => setShowRefererModal(true)}>
                      <div className="flex items-center gap-1">
                        <span className="text-lg"><RiDiscountPercentFill /></span>
                        <span>Refer & Earn</span>
                      </div>
                    </button>

                    <Link to="/my-referrals" className="hover:underline">
                      My Referrals
                    </Link>

                    <button
                      onClick={handleLogout}
                      className="text-red-600 hover:underline rounded-md"
                    >
                      Logout
                    </button>
                  </>
                ) : (
                  <>
                    <Link
                      to={`/signup${referer ? `?referer=${referer}` : ''}`}
                      className="text-black hover:underline p-2 px-2 rounded-md"
                    >
                      Sign up
                    </Link>
                    <Link
                      to="/login"
                      className="text-black hover:underline p-2 px-2 rounded-md"
                    >
                      Login
                    </Link>
                  </>
                )}
              </div>
            </div>

          </div>
        </div>
      </header>

      {currentUser && (
        <Modal
          isOpen={showRefererModal}
          onRequestClose={() => setShowRefererModal(false)}
          contentLabel="Referer Modal"
          style={customStyles}
        >
          <div>
            <h1 className="text-2xl">Share your referral link</h1>
            <p className="mb-5 text-slate-500 font-thin text-xs">Refer Friends, Get Points, Redeem Awesome Rewards!</p>

            <p className="text-xs text-blue-600">https://contractorsclearinghouse.pro?referer={currentUser.uid}</p>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Header;
