import Footer from "./components/Footer";
import Header from "./components/Header";

const TermsOfService = () => {
    return (
        <>
            <Header />

            <div className="container mx-auto my-10 px-8">
                <h1 className="text-2xl">Terms of Service</h1>
                <p className="mb-5">Effective Date: 30 August 2024</p>
                <p>
                    Welcome to ContractorsClearingHouse.pro! These Terms of Service ("Terms") govern your access to and use of our website, mobile application, and services (collectively, the "Platform") provided by [Company Name] ("Company," "we," "us," or "our"). By accessing or using the Platform, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, you must not use the Platform.
                </p>
                <h2 className="text-lg mt-5 mb-2">1. Platform Overview</h2>
                <p>
                    The Platform connects clients ("Clients") seeking various services with independent contractors who offer such services. The Company itself does not provide any services and is not a party to any contracts between Clients and Contractors.
                </p>
                <h2 className="text-lg mt-5 mb-2">2. Eligibility</h2>
                <p>
                    To use the Platform, you must be at least 18 years old and capable of entering into a legally binding contract. By using the Platform, you represent and warrant that you meet these requirements.
                </p>
                <h2 className="text-lg mt-5 mb-2">3. Account Registration</h2>
                <ul>
                    <li>
                        <strong>Account Creation:</strong> To access certain features of the Platform, you must register for an account ("Account"). You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
                    </li>
                    <li>
                        <strong>Account Security:</strong> You are responsible for safeguarding your password and for any activities or actions under your Account, whether or not you have authorized such activities or actions. You agree to notify us immediately of any unauthorized use of your Account.
                    </li>
                </ul>
                <h2 className="text-lg mt-5 mb-2">4. Platform Use</h2>
                <ul>
                    <li>
                        <strong>Client Responsibilities:</strong> Clients are responsible for specifying the services they require and for ensuring that their requirements comply with applicable laws and regulations. Clients are solely responsible for selecting a Contractor and for negotiating the terms of the service agreement with the Contractor.
                    </li>
                    <li>
                        <strong>Contractor Responsibilities:</strong> Contractors are responsible for the quality and delivery of the services they offer and for ensuring that their services comply with applicable laws and regulations. Contractors are solely responsible for determining the terms and conditions of their agreements with Clients.
                    </li>
                </ul>
                <h2 className="text-lg mt-5 mb-2">5. Fees and Payment</h2>
                <ul>
                    <li>
                        <strong>Service Fees:</strong> The Platform may charge fees to Contractors and/or Clients for the use of the Platform or for specific features. The applicable fees and payment terms will be provided at the time of the transaction.
                    </li>
                    <li>
                        <strong>Payment Processing:</strong> Payment processing services on the Platform are provided by third-party payment processors. You agree to provide accurate payment information and authorize us to share this information with the payment processors to facilitate transactions.
                    </li>
                    <li>
                        <strong>Compensation to the Platform:</strong> Contractors agree to pay and/or compensate ContractorsClearingHouse.pro or any of its associates between 5% to 10% or a flat fee, based on the discretion of the Company or its assigned associates. Clients agree that ContractorsClearingHouse.pro deserves and will receive compensation from the bid or above the bid, based upon the Company's discretion. Both Contractors and Clients agree that ContractorsClearingHouse.pro can be compensated for the actual bid and all other activities chosen by the Company.
                    </li>
                </ul>
                <h2 className="text-lg mt-5 mb-2">6. User Conduct</h2>
                <p>
                    By using the Platform, you agree not to:
                </p>
                <ul>
                    <li>Violate any laws, third-party rights, or our policies;</li>
                    <li>Post false, inaccurate, misleading, or defamatory content;</li>
                    <li>Discriminate against or harass anyone on any basis;</li>
                    <li>Engage in any activity that could harm the reputation of the Platform.</li>
                </ul>
                <h2 className="text-lg mt-5 mb-2">7. Dispute Resolution</h2>
                <p>
                    <strong>Disputes Between Users:</strong> The Company is not a party to any service contract between Clients and Contractors. Users agree to resolve any disputes directly with each other and to release the Company from any claims, demands, and damages arising out of or in any way connected with such disputes.
                </p>
                <p>
                    <strong>Disputes with the Company:</strong> If a dispute arises between you and the Company, we encourage you to contact us directly to seek a resolution. If the dispute cannot be resolved informally, it shall be resolved through binding arbitration as detailed below.
                </p>
                <h2 className="text-lg mt-5 mb-2">8. Limitation of Liability</h2>
                <p>
                    To the maximum extent permitted by law, the Company, including ContractorsClearingHouse.pro, its subsidiaries, associates, partners, and owners, shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or goodwill, arising out of or in connection with your use of the Platform or any offline or online activities, directly or indirectly, for any business between Contractor or Client. This limitation of liability applies whether the claim is based on contract, tort, statute, or any other legal theory.
                </p>
                <h2 className="text-lg mt-5 mb-2">9. Indemnification</h2>
                <p>
                    You agree to indemnify, defend, and hold harmless the Company, its affiliates, and their respective directors, officers, employees, and agents from and against any and all claims, liabilities, damages, losses, and expenses arising from your use of the Platform, your violation of these Terms, or any activities or business conducted offline or online, directly or indirectly, between Contractors and Clients.
                </p>
                <h2 className="text-lg mt-5 mb-2">10. Modifications to the Terms</h2>
                <p>
                    The Company reserves the right to modify these Terms at any time. Any changes will be effective immediately upon posting on the Platform. Your continued use of the Platform after any such changes constitutes your acceptance of the new Terms.
                </p>
                <h2 className="text-lg mt-5 mb-2">11. Governing Law</h2>
                <p>
                    These Terms shall be governed by and construed in accordance with the laws of the USA, without regard to its conflict of law principles.
                </p>
                <h2 className="text-lg mt-5 mb-2">12. Contact Information</h2>
                <p>
                    If you have any questions about these Terms, please contact us at support@contractorsclearinghouse.pro
                </p>
            </div>

            <Footer />
        </>
    );
}

export default TermsOfService;
