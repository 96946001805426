import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import { db } from "../firebase";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { ClipLoader } from "react-spinners";

const ViewChat = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [submission, setSubmission] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchSubmissionById = async () => {
      try {
        const docRef = doc(db, "submissions", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setSubmission(docSnap.data());
        } else {
          toast.error("No such submission found!");
        }
      } catch (error) {
        console.error("Error fetching submission:", error);
        toast.error("Failed to fetch submission. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissionById();
  }, [id]);

  const fetchUserByUserId = async (userId) => {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        return userDoc.data();
      } else {
        console.log("No such user!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      return null;
    }
  };

  const fetchMessages = () => {
    const messagesRef = query(
      collection(db, "chats", id, "messages"),
      orderBy("createdAt", "asc")
    );

    return onSnapshot(messagesRef, async (snapshot) => {
      const msgs = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const messageData = doc.data();
          const userData = await fetchUserByUserId(messageData.senderId);

          return {
            id: doc.id,
            ...messageData,
            user: userData,
          };
        })
      );
      setMessages(msgs);
    });
  };

  useEffect(() => {
    const unsubscribe = fetchMessages();
    return () => unsubscribe();
  }, []);

  return (
    <div className="container mx-auto my-5 px-4">
      {loading ? (
        <div className="flex justify-center items-center h-32">
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
        </div>
      ) : submission ? (
        <div className="border border-slate-400 mb-5 p-4 rounded-sm">
          <span className="text-sm text-slate-500">
            {format(
              submission.submittedAt.toDate ? submission.submittedAt.toDate() : new Date(),
              "Pp"
            )}
          </span>
          <h2 className="text-2xl font-bold mb-4">Project Details</h2>
          {submission.requirements.map((item, index) => (
            <div key={index} className="mb-4">
              <p className="font-semibold">{item.question}</p>
              <p className="text-gray-700">{item.answer}</p>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-red-500">No submission found with the provided ID.</p>
      )}

      <div className="border border-slate-400 p-4 mb-5 rounded-md shadow-md">
        <div className="messages-list max-h-96 overflow-y-auto space-y-4">
          {messages.map((msg) => (
            <div key={msg.id} className="message mb-3">
              <p>
                <strong className="font-medium">{msg.user?.role || "User"}</strong>: {msg.message}
              </p>
              {msg.createdAt && (
                <p className="text-xs text-gray-500">
                  {format(
                    msg.createdAt.toDate ? msg.createdAt.toDate() : new Date(),
                    "Pp"
                  )}
                </p>
              )}
              {msg.quote && (
                <div className="quote-details mt-2 p-3 border border-slate-300 rounded-md bg-slate-50">
                  <p className="text-sm">Quote: ${msg.quote.amount}</p>
                  <p className="text-sm">Status: {msg.quoteStatus}</p>
                  {msg.quoteStatus === "sent" && (
                    <div className="quote-actions flex space-x-2 mt-2">
                      <button
                        disabled={true}
                        className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600 transition"
                      >
                        Accept
                      </button>
                      <button
                        disabled={true}
                        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition"
                      >
                        Reject
                      </button>
                    </div>
                  )}
                  {msg.quoteStatus === "sent" && (
                    <button
                      disabled={true}
                      className="bg-yellow-500 text-white px-3 py-1 rounded mt-2 hover:bg-yellow-600 transition"
                    >
                      Withdraw Quote
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewChat;
