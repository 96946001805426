import Footer from "./components/Footer";
import Header from "./components/Header";

const PrivacyPolicy = () => {
    return (
        <>
            <Header />

            <div className="container mx-auto my-10 px-8">
                <h1 className="text-2xl">Privacy Policy</h1>

                <h2 className="text-lg mt-5 mb-2">1. Introduction</h2>

                Welcome to ContractorsClearingHouse.pro ("the Platform"). We are committed to protecting the privacy of our users. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services. By using the Platform, you consent to the practices described in this policy.

                <h2 className="text-lg mt-5 mb-2">2. Information We Collect</h2>

                <h3 className="my-4">a. Personal Information</h3>
                <p className="mb-2">When you register for an account, use our services, or communicate with us, we may collect the following personal information:</p>

                <li>Name</li>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Physical address</li>
                <li>Payment information</li>
                <li>Business details (e.g., company name, registration number, service offerings)</li>

                <h3 className="my-4">b. Non-Personal Information</h3>
                <p className="mb-2">We may collect non-personal information automatically when you interact with our website, including:</p>

                <li>IP address</li>
                <li>Browser type</li>
                <li>Device information</li>
                <li>Pages visited and time spent on the site</li>
                <li>Cookies and similar tracking technologies</li>

                <h2 className="text-lg mt-5 mb-2">3. How We Use Your Information</h2>

                We use the information we collect to:

                <li>Provide, operate, and maintain the Platform</li>
                <li>Improve our services and enhance user experience</li>
                <li>Process transactions and manage payments</li>
                <li>Communicate with you regarding your account, updates, or promotional materials</li>
                <li>Monitor and analyze usage and trends to improve the Platform</li>
                <li>Prevent fraudulent activity and ensure the security of our services</li>

                <h2 className="text-lg mt-5 mb-2">4. Sharing Your Information</h2>

                We may share your information with:

                Service providers: Third-party companies that help us operate our business, such as payment processors, hosting services, and email delivery services.
                Legal authorities: When required by law, we may disclose your information to comply with a subpoena, regulation, legal process, or government request.
                Business transfers: If we are involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that business deal.

                <h2 className="text-lg mt-5 mb-2">5. Cookies and Tracking Technologies</h2>

                We use cookies and similar technologies to collect and store information about your preferences and activities on our website. You can control your cookie preferences through your browser settings. However, disabling cookies may affect your ability to use certain features of the Platform.

                <h2 className="text-lg mt-5 mb-2">6. Data Security</h2>

                We implement reasonable security measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure.

                <h2 className="text-lg mt-5 mb-2">7. Your Rights and Choices</h2>

                Depending on your location, you may have the following rights regarding your personal information:

                <li>Access: Request a copy of your personal information we hold.</li>
                <li>Correction: Request corrections to any inaccuracies in your personal information.</li>
                <li>Deletion: Request deletion of your personal information, subject to legal obligations.</li>
                <li>Objection: Object to the processing of your personal information for certain purposes.</li>
                <li>Data portability: Request the transfer of your personal information to another service provider.</li>
                To exercise these rights, please contact us at support@contractorsclearinghouse.pro

                <h2 className="text-lg mt-5 mb-2">8. Third-Party Links</h2>

                <p>
                    The Platform may contain links to third-party websites. We are not responsible for the privacy practices or content of those sites. Please review the privacy policies of any third-party sites you visit.
                </p>

                <h2 className="text-lg mt-5 mb-2">9. Children's Privacy</h2>

                <p>The Platform is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete it.</p>

                <h2 className="text-lg mt-5 mb-2">10. Changes to This Privacy Policy</h2>

                <p>
                    We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Last updated" date. We encourage you to review this Privacy Policy periodically for any changes.
                </p>

                <h2 className="text-lg mt-5 mb-2">11. Contact Us</h2>

                <p>If you have any questions or concerns about this Privacy Policy, please contact us at support@contractorsclearinghouse.pro</p>

                <p className="mt-5">Last updated: 30 August 2024</p>
            </div>
            <Footer />
        </>
    )
}

export default PrivacyPolicy;